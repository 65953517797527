<template>
  <div class="">
    <div class="grid">
      <div class="toolbar mb-2" :key="key">
        <div :class="supportCalled || (isLiveChat && supportConnection) ? 'grid grid-cols-3' : 'grid grid-cols-2'">

          <div :class="`float-left self-center ${finished ? 'col-span-2' : ''}`">
            {{ expanded ? user.name : truncate(user.name, finished ? 25 : 10) }}

            {{ showEnableConnection && connectionSpeed !== null
              ? `(${connectionSpeed} Mb)` : showEnableConnection ? '(...)' : ''}}
          </div>

          <div class="float-right self-center" v-if="!finished">

            <font-awesome-icon
              v-if="showExpand"
              @click="toggleExpand()"
              :icon="expanded ? 'compress' : 'expand'"
              class="float-right ml-4 cursor-pointer"
              v-tooltip.top="{
                content: expandTooltipContent
              }"
            />

            <font-awesome-icon
              @click="refresh(true)"
              icon="sync"
              class="float-right ml-4 cursor-pointer"
              v-tooltip.top="{
                content: 'Atualizar',
                delay: { hide: 100 }
              }"
            />

            <font-awesome-icon
              @click="toggleVolume()"
              :icon="this.volumeEnabled ? 'volume-up' : 'volume-mute'"
              :class="['float-right cursor-pointer', this.volumeEnabled ? 'volume-enabled' : 'volume-disabled']"
              v-tooltip.top="{
                content: this.volumeEnabled ? 'Desabilitar som' : 'Habilitar som',
                delay: { hide: 100 }
              }"
            />
          </div>


          <div class="float-right self-center support-container" v-if="isLiveChat && supportConnection">

            <font-awesome-icon
              v-if="!supportInProgress && supportConnection"
              @click="confirmSupport()"
              icon="phone"
              :class="['float-right ml-4 cursor-pointer confirm-support', supportCalled && !supportInProgress ? 'bell' : '']"
              v-tooltip.top="{
                content: supportCalled ? 'Atender chamada' : 'Abrir chamada',
                delay: { hide: 100 }
              }"
            />

            <font-awesome-icon
              v-if="incomeSupportInProgress || outcomeSupportInProgress"
              @click="cancelSupport()"
              icon="phone-slash"
              class="float-right ml-4 cursor-pointer deny-support"
              v-tooltip.top="{
                content: 'Cancelar chamada',
                delay: { hide: 100 }
              }"
            />

          </div>
        </div>
      </div>
      <edu-placeholder-action
        v-if="finished"
        class="cursor-none border-faint-grey border-solid border"
        :label="$t('questionario-finalizado')"
        style="height:98px !important;"
      >
        <feather-icon
          icon="ThumbsUpIcon"
          svgClasses="h-6 w-6 placeholder-action-icon-svg"
          class="ml-1 placeholder-action-icon"
        />
      </edu-placeholder-action>
      <div v-else :class="
        [
          'grid',
          expanded ? 'expanded-box-player' : 'collapsed-box-player',
          expandedCamPlayer ? 'grid-cols-1' : 'grid-cols-1', // grid-cols-2
          camConnectError ? 'player-border-error' : 'player-border',
          showUserMediaVideoPlayer ? 'player-border-success' : ''
        ]">

        <div v-show="!expandedCamPlayer">
          <div
            :id="`cam-player-placeholder-${user.id}`"
            v-show="!showUserMediaVideoPlayer || connectionLoading"
            :class="[
              !showUserMediaVideoPlayer || connectionLoading ? 'grid' : '',
              'justify-items-center items-center vs-loading vs-con-loading__container',
              expanded ? 'player-placeholder-class-expanded' : 'player-placeholder-class'
            ]"
          >
            <font-awesome-icon
              icon="tv"
              :class="['placeholder-icon', camConnectError ? 'placeholder-icon-error' : '']"
              v-tooltip.right="{
                content: camConnectError ? 'Erro na conexão' : ''
              }"
            />
          </div>
          <div v-show="showUserMediaVideoPlayer && !connectionLoading">
            <video
              autoplay
              :muted="!volumeEnabled"
              ref="userMediaVideoPlayer"
              :class="[expanded ? 'expanded-player' : 'player', 'cursor-pointer']">
            </video>
          </div>
          <!--
          <div>
            <VideoPlayer ref="videoPlayer"
              class="vjs-custom-skin"
              playsinline="true"
              :options="playerOptions">
            </VideoPlayer>
          </div>
          -->
        </div>
      </div>
    </div>

    <div v-if="expanded && showExpand" class="grid grid-rows-1 pt-4">

      <ComplexEditor
        v-bind:editor_data.sync="commentData"
        :placeholder="$t('informe-um-comentario-aqui')"
        class="break-words overflow-y-auto overflow-x-hidden"
        style="max-height:200px;max-width:100%;"
      ></ComplexEditor>

      <div class="flex flex-row gap-2 py-2 items-end justify-end">
        <vs-select
          class="float-right"
          :label="$t('criticidade')"
          v-model="commentSeverity"
        >
          <vs-select-item value="L" text="Baixa"/>
          <vs-select-item value="M" text="Média"/>
          <vs-select-item value="H" text="Alta"/>
        </vs-select>
        <vs-button
          class="float-right mt-2"
          @click="addComment()"
          >{{ $t('salvar-comentario') }}</vs-button
        >
      </div>
    </div>

  </div>
</template>

<script>

import ProctoringService from '@/services/api/ProctoringService'
import ComplexEditor from '@/views/apps/questionnaires/elements/ComplexEditor'
import {Peer} from 'peerjs'
// import Peer from 'simple-peer'
// import wrtc from 'wrtc'
import EduPlaceholderAction from '@/components/EduPlaceholderAction.vue'
import { PROCTORING_SOCKET_TYPE, PROCTORING_STREAM_TYPE } from '@/util/Enums'
import cheet from 'cheet.js'

export default {
  components: {
    ComplexEditor,
    EduPlaceholderAction
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    answerId: {
      type: Number,
      default: null
    },
    proctoringService: {
      type: ProctoringService,
      default: null
    },
    roomId: {
      type: Number,
      default: null
    },
    finished: {
      type: Boolean,
      default: false
    },
    mediaStream: {
      type: Object,
      default: null
    },
    showExpand: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: null
    }
  },
  data: function() {
    return {
      showUserMediaVideoPlayer: false,
      userMediaVideoPlayer: null,
      camPeer: null,
      expanded: false,
      expandedCamPlayer: false,
      camConnectError: false,
      camConnectInProgress: false,
      volumeEnabled: true,
      commentData: '',
      commentSeverity: 'L',
      reconnectCamPeerInterval: null,
      camConnected: false,
      // Flag to indicate when support are called
      supportCalled: false,
      // Income support chat in progress
      incomeSupportInProgress: false,
      // Outcome support chat in progress
      outcomeSupportInProgress: false,
      // Actual support connection
      supportConnection: null,
      connectionLoading: false,
      connectionSpeed: null,
      showEnableConnection: false,
      // Video player
      playerOptions: {
        autoplay: true,
        controls: false,
        controlBar: {
          currentTimeDisplay: false,
          timeDivider: false,
          durationDisplay: false
        },
        liveui: true,
      },
      key: 0,
      camCallInterval: null,
      camCallTry: 0
    }
  },
  computed: {
    expandTooltipContent() {
      return this.expanded ? this.$t('minizar-tela') : this.$t('maximizar-tela')
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    pqUserId() {
      return `pq_user_${this.user.id}_${this.config.questionnaire.short_url_id}_cam`
    },
    supportInProgress() {
      return this.incomeSupportInProgress || this.outcomeSupportInProgress
    },
    isLiveChat() {
      if (this.config && this.config.record_medium) {
        return this.config.record_medium === PROCTORING_STREAM_TYPE.LiveChat || this.config.record_medium === PROCTORING_STREAM_TYPE.LiveChatRecord
      }
      return false
    },
    showSupportPhoneCall() {
      return this.isLiveChat && this.supportConnection
    },
    player () {
      return this.$refs.videoPlayer.player
    },
    pmUserId() {
      return `pm_user_${this.user.id}_${this.activeUserInfo.id}_${this.roomId}_cam`
    }
  },
  methods: {
    refresh(retry) {
      if (!this.isEmpty(this.mediaStream)) {

        this.connectionLoading = false
        this.showCamLoading()
        if (!this.camConnectInProgress) {
          this.camConnectInProgress = true
          this.createProctoringCamPeer()
        }

      } else if (retry) {
        this.startSupervision()
      }

    },
    reconnectCamPeer() {
      if (!this.reconnectCamPeerInterval) {
        this.reconnectCamPeerInterval = setInterval(() => {
          if (this.camConnected) {
            clearInterval(this.reconnectCamPeerInterval)
            this.reconnectCamPeerInterval = null
          } else if (!this.camConnectInProgress) {
            this.refresh(false)
          }
        }, 2000)
      }
    },
    toggleExpand() {
      this.expanded = !this.expanded
      this.$emit('expanded', this.expanded ? this.user.id : null)
    },
    closeConnections() {
      const connections = this._.get(this.camPeer, `connections.${this.pqUserId}`, []) || []
      if (connections.length > 0) {
        for (const connection of connections) {
          connection.close()
        }
      }
    },
    createProctoringCamPeer() {
      this.showCamLoading()
      if (this.camPeer) {
        this.closeConnections()
        this.camPeer.destroy()
        this.camPeer.disconnect()
        this.camPeer = null
      }

      if (this.supportConnection) {
        this.supportConnection.close()
        this.supportConnection = null
      }

      this.camPeer = new Peer(this.pmUserId, {
        host: process.env.VUE_APP_PEER_SERVER_URL,
        port: process.env.VUE_APP_PEER_SERVER_PORT,
        path: process.env.VUE_APP_PEER_PATH,
        key: process.env.VUE_APP_PEER_KEY,
        config: {'iceServers': [
          { url: `${process.env.VUE_APP_STUN_SERVER}` },
          { url: `turn:${process.env.VUE_APP_PEER_SERVER_URL}:${process.env.VUE_APP_TURN_SERVER_PORT}`, username: `${process.env.VUE_APP_TURN_SERVER_USERNAME}`, credential: `${process.env.VUE_APP_TURN_SERVER_CREDENTIAL}` }
        ]},
        // debug: 3
      })

      this.camPeer.on('open', (id) => {
        this.call()
      })

      this.camPeer.on('connection', (conn) => {

        // Listen form answer data
        conn.on('data', (data) => {
          if (this.isLiveChat && data && data.type === PROCTORING_SOCKET_TYPE.CallSupport) {
            this.playPhoneRing()
            this.supportCalled = true
          } else if (this.isLiveChat && data && data.type === PROCTORING_SOCKET_TYPE.OpenSupportCanceled) {
            this.supportCalled = false
            this.incomeSupportInProgress = false
            this.outcomeSupportInProgress = false
          } else if (data && data.type === PROCTORING_SOCKET_TYPE.ConnectionSpeed) {
            const result = data.data.result
            this.connectionSpeed = result ? result.toFixed(1) : null
          }
        })

        this.supportConnection = conn
        console.log('after this.supportConnection...')
        this.key++
      })

      this.camPeer.on('error', (error) => {
        this.camConnectError = true
        this.camConnectInProgress = false
        this.$exceptionService.quickRegister(
          this.$t('proctoring-supervisao-erro-de-conexao-stream-supervisor-greater-than-usuario'),
          'proctoring_campeer_error',
          {error: error, pmUserId: this.pmUserId}
        )
      })

      this.camPeer.on('disconnected', (error) => {
        this.$exceptionService.quickRegister(
          this.$t('proctoring-supervisao-conexao-stream-supervisor-greater-than-usuario-desconectada'),
          'proctoring_campeer_disconnected',
          {error: error, pmUserId: this.pmUserId}
        )
      })
    },
    call() {

      this.camCallInterval = setInterval(() => {
        this.camPeer.connect(this.pqUserId)
        const camCall = this.camPeer.call(this.pqUserId, this.mediaStream)

        if (!camCall) {
          this.camCallTry++
        }

        if ((camCall) || (!camCall && this.camCallTry > 3)) {
          clearInterval(this.camCallInterval)
        }

        if (camCall) {

          camCall.on('disconnect', (error) => {
            this.$exceptionService.quickRegister(
              this.$t('proctoring-supervisao-conexao-chamada-supervisor-greater-than-usuario-desconectada'),
              'proctoring_camcall_disconnected',
              {error: error, pmUserId: this.pmUserId}
            )
            console.log('camCall disconnect...', error)
          })

          camCall.on('error', (error) => {
            this.$exceptionService.quickRegister(
              this.$t('proctoring-supervisao-conexao-chamada-supervisor-greater-than-usuario-desconectada'),
              'proctoring_camcall_disconnected',
              {error: error, pmUserId: this.pmUserId}
            )
            console.log('camCall error...', error)
          })

          camCall.on('close', () => {
            camCall.close()
          })

          camCall.on('stream', (remoteStream) => {
            this.closeCamLoading()
            this.showUserMediaVideoPlayer = true
            this.camConnected = true

            if (this.$refs.userMediaVideoPlayer) {
              this.$refs.userMediaVideoPlayer.srcObject = remoteStream
            }
          })

          const pcCam = camCall.peerConnection
          if (pcCam) {
            pcCam.oniceconnectionstatechange = () => {
              console.log('pcCam', pcCam, pcCam.iceConnectionState)

              if (pcCam.iceConnectionState === 'connected') {
                this.camConnectInProgress = false
                this.closeCamLoading()
                this.camConnectError = false
              }

              if (pcCam.iceConnectionState === 'failed') {

                console.log('Cam failed')

                this.camConnected = false
                this.supportConnection = null
                // this.connectionLoading = false
                this.reconnectCamPeer()
              }

              if (pcCam.iceConnectionState === 'disconnected') {
                this.$exceptionService.quickRegister(
                  this.$t('proctoring-supervisao-conexao-chamada-supervisor-greater-than-usuario-ice-disconectada'),
                  'proctoring_iceconnection_disconnected',
                  {
                    error: 'proctoring proctor camera iceconnection disconnected',
                    pmUserId: this.pmUserId
                  }
                )
                this.camConnected = false
                this.supportConnection = null
                this.reconnectCamPeer()
              }
            }
          }
        }
      }, 1000)
    },
    showCamLoading() {
      if (!this.connectionLoading) {
        this.connectionLoading = true
        const camPlayerPlaceholder = document.getElementById(`cam-player-placeholder-${this.user.id}`)
        if (camPlayerPlaceholder) {
          this.$vs.loading({
            container: camPlayerPlaceholder,
            scale: 0.6
          })
        }
      }
    },
    closeCamLoading() {
      if (this.connectionLoading) {
        const camPlayerPlaceholder = document.getElementById(`cam-player-placeholder-${this.user.id}`)
        if (camPlayerPlaceholder) {
          this.$vs.loading.close(camPlayerPlaceholder)
          this.connectionLoading = false
        }
      }
    },
    addComment() {
      this.$vs.loading()

      const data = ({
        'type': 'comment',
        'data': {text: this.commentData},
        'severity': this.commentSeverity || 'L'
      })

      this.proctoringService.addNewEvent(this.user.classroom_user_id, data).then(() => {
        this.commentData = ''
        this.$vs.loading.close()
        this.$root.$emit('new_event_added')
        this.notifySuccess(this.$vs, this.$t('comentario-adicionado-com-sucesso'))
      })
    },
    toggleVolume() {
      this.volumeEnabled = !this.volumeEnabled
    },
    startSupervision() {
      this.$emit('supervise', this.user)
    },
    confirmSupport() {

      if (this.supportConnection && this.isLiveChat) {
        this.supportConnection.send({
          type: PROCTORING_SOCKET_TYPE.OpenSupportConfirmed,
          peerId: this.pqUserId,
          user_id: this.activeUserInfo.id,
          user_name: this.activeUserInfo.name
        })

        if (this.supportCalled) {
          this.incomeSupportInProgress = true
        } else {
          this.outcomeSupportInProgress = true
        }
      }

    },
    cancelSupport() {

      if (this.supportConnection && this.supportInProgress) {
        this.supportConnection.send({
          type: PROCTORING_SOCKET_TYPE.OpenSupportCanceled,
          peerId: this.pqUserId,
          user_id: this.activeUserInfo.id
        })
        this.incomeSupportInProgress = false
        this.outcomeSupportInProgress = false
        this.supportCalled = false
      }
    },
    stopMonitor() {
      this.camPeer.disconnect()
      this.camPeer.destroy()
      this.camPeer = null
      this.camConnected = false
      this.camConnectInProgress = false
      this.showUserMediaVideoPlayer = false
    },
    startVideoPlayer() {
      const token = this.$store.getters.userToken
      const contentQuestionnaireId = this.config.questionnaire_id
      const answerId = this.answerId
      const fileExt = 'webm'
      const src = `${process.env.VUE_APP_API_PROCTORING_BASE_URL}/p/${token}/${contentQuestionnaireId}-${answerId}-live.${fileExt}`

      const video = {
        withCredentials: false,
        type: `video/${fileExt}`,
        src: src
      }

      this.player.reset()
      this.player.src(video)
    },
    collapseAll(userId) {
      if (this.user.id !== userId) {
        this.expanded = false
      }
    },
    toggleExpanded() {
      this.expanded = !this.expanded
      this.$emit('expanded', this.expanded ? this.user.id : null)
    },
    startSupervisionListener() {
      console.log(`start_supervice_room_.${this.roomId}`)
      this.refresh()
    },
    exitRoomSupervisionListener() {
      this.stopMonitor()
    },
    toggleRoomSupport(value) {
      if (this.supportConnection) {
        if (value && !this.supportInProgress) {
          this.confirmSupport()
        } else if (this.supportInProgress) {
          this.cancelSupport()
        }
      }
    }
  },
  mounted() {

    if (!this.finished) {

      if (this.$refs.userMediaVideoPlayer) {
        this.$refs.userMediaVideoPlayer.addEventListener('loadedmetadata', () => {
          this.$refs.userMediaVideoPlayer.play()
        })
      }

      this.$root.$on('collapse_all', this.collapseAll )
      this.$root.$on(`maximize_stream_player.${this.user.id}`, this.toggleExpanded)
      this.$root.$on(`start_supervice_room_.${this.roomId}`, this.startSupervisionListener)
      this.$root.$on(`toggle_room_volume.${this.roomId}`, this.toggleVolume)
      this.$root.$on('exit_room_supervision', this.exitRoomSupervisionListener)
      this.$root.$on(`toggle_room_support.${this.roomId}`, this.toggleRoomSupport)
    }

    cheet('up up s s', () => {
      if (this.supportConnection) {
        this.showEnableConnection = true
        this.supportConnection.send({
          type: PROCTORING_SOCKET_TYPE.AskConnectionSpeed
        })
      }
    })

    cheet('down down s s', () => {
      this.connectionSpeed = null
      this.showEnableConnection = false
    })
  },
  created() {
    if (!this.showExpand) this.expanded = true
  },
  beforeMount() {
  },
  destroyed() {
    this.$root.$off('collapse_all', this.collapseAll )
    this.$root.$off(`maximize_stream_player.${this.user.id}`, this.maximize)
    this.$root.$off(`start_supervice_room_.${this.roomId}`, this.startSupervisionListener)
    this.$root.$off(`toggle_room_volume.${this.roomId}`, this.toggleVolume)
    this.$root.$off('exit_room_supervision', this.exitRoomSupervisionListener)
    this.$root.$off(`toggle_room_support.${this.roomId}`, this.toggleRoomSupport)

    console.log('stream player destroyed')
    if (this.reconnectCamPeerInterval) {
      clearInterval(this.reconnectCamPeerInterval)
    }

    if (this.camPeer) {
      this.closeConnections()
      this.camPeer.disconnect()
      this.camPeer.destroy()
      this.camPeer = null
    }

    if (this.supportConnection) {
      this.supportConnection.close()
      this.supportConnection = null
    }

  }
}
</script>

<style lang="scss">

.player {
  width: 100%;
  height: 100px;
  padding: 5px;
}

.fliped-player {
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
}

.expanded-player {
  width: 100%;
  height: 500px;
  padding: 5px;
}

.player-border {
  border: 1px solid $grey;
  max-height: 100px;
}

.player-border-error {
  border: 1px solid $danger;
}

.player-border-success {
  border: 1px solid $success;
}

.expanded-box-player {
  height: 500px !important;
  max-height: 500px !important;
}

.collapsed-box-player {
  height: 100px !important;
  max-height: 100px !important;
}

.placeholder-icon {
  font-size: 25px;
  opacity: 0.5;
  display: flex;
  width: 100%;
}

.placeholder-icon-error {
  color: $danger;
}

.player-placeholder-class {
  height: 98px;
  z-index: 10;
}

.player-placeholder-class-expanded {
  height: 100%;
  z-index: 10;
}

.volume-enabled {
  font-size: 15px !important;
  color: $success;
}

.volume-disabled {
  font-size: 15px !important;
  color: $danger;
}

.support-container {

}

.confirm-support {
  color: $success;
}

.deny-support {
  color: $danger;
}

</style>
