var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "grid" },
      [
        _c("div", { key: _vm.key, staticClass: "toolbar mb-2" }, [
          _c(
            "div",
            {
              class:
                _vm.supportCalled || (_vm.isLiveChat && _vm.supportConnection)
                  ? "grid grid-cols-3"
                  : "grid grid-cols-2",
            },
            [
              _c(
                "div",
                {
                  class:
                    "float-left self-center " +
                    (_vm.finished ? "col-span-2" : ""),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.expanded
                          ? _vm.user.name
                          : _vm.truncate(_vm.user.name, _vm.finished ? 25 : 10)
                      ) +
                      " " +
                      _vm._s(
                        _vm.showEnableConnection && _vm.connectionSpeed !== null
                          ? "(" + _vm.connectionSpeed + " Mb)"
                          : _vm.showEnableConnection
                          ? "(...)"
                          : ""
                      ) +
                      " "
                  ),
                ]
              ),
              !_vm.finished
                ? _c(
                    "div",
                    { staticClass: "float-right self-center" },
                    [
                      _vm.showExpand
                        ? _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: _vm.expandTooltipContent,
                                },
                                expression:
                                  "{\n              content: expandTooltipContent\n            }",
                                modifiers: { top: true },
                              },
                            ],
                            staticClass: "float-right ml-4 cursor-pointer",
                            attrs: {
                              icon: _vm.expanded ? "compress" : "expand",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleExpand()
                              },
                            },
                          })
                        : _vm._e(),
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top",
                            value: {
                              content: "Atualizar",
                              delay: { hide: 100 },
                            },
                            expression:
                              "{\n              content: 'Atualizar',\n              delay: { hide: 100 }\n            }",
                            modifiers: { top: true },
                          },
                        ],
                        staticClass: "float-right ml-4 cursor-pointer",
                        attrs: { icon: "sync" },
                        on: {
                          click: function ($event) {
                            return _vm.refresh(true)
                          },
                        },
                      }),
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top",
                            value: {
                              content: this.volumeEnabled
                                ? "Desabilitar som"
                                : "Habilitar som",
                              delay: { hide: 100 },
                            },
                            expression:
                              "{\n              content: this.volumeEnabled ? 'Desabilitar som' : 'Habilitar som',\n              delay: { hide: 100 }\n            }",
                            modifiers: { top: true },
                          },
                        ],
                        class: [
                          "float-right cursor-pointer",
                          this.volumeEnabled
                            ? "volume-enabled"
                            : "volume-disabled",
                        ],
                        attrs: {
                          icon: this.volumeEnabled
                            ? "volume-up"
                            : "volume-mute",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleVolume()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isLiveChat && _vm.supportConnection
                ? _c(
                    "div",
                    {
                      staticClass: "float-right self-center support-container",
                    },
                    [
                      !_vm.supportInProgress && _vm.supportConnection
                        ? _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: _vm.supportCalled
                                    ? "Atender chamada"
                                    : "Abrir chamada",
                                  delay: { hide: 100 },
                                },
                                expression:
                                  "{\n              content: supportCalled ? 'Atender chamada' : 'Abrir chamada',\n              delay: { hide: 100 }\n            }",
                                modifiers: { top: true },
                              },
                            ],
                            class: [
                              "float-right ml-4 cursor-pointer confirm-support",
                              _vm.supportCalled && !_vm.supportInProgress
                                ? "bell"
                                : "",
                            ],
                            attrs: { icon: "phone" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmSupport()
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.incomeSupportInProgress ||
                      _vm.outcomeSupportInProgress
                        ? _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: "Cancelar chamada",
                                  delay: { hide: 100 },
                                },
                                expression:
                                  "{\n              content: 'Cancelar chamada',\n              delay: { hide: 100 }\n            }",
                                modifiers: { top: true },
                              },
                            ],
                            staticClass:
                              "float-right ml-4 cursor-pointer deny-support",
                            attrs: { icon: "phone-slash" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelSupport()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _vm.finished
          ? _c(
              "edu-placeholder-action",
              {
                staticClass:
                  "cursor-none border-faint-grey border-solid border",
                staticStyle: { height: "98px !important" },
                attrs: { label: _vm.$t("questionario-finalizado") },
              },
              [
                _c("feather-icon", {
                  staticClass: "ml-1 placeholder-action-icon",
                  attrs: {
                    icon: "ThumbsUpIcon",
                    svgClasses: "h-6 w-6 placeholder-action-icon-svg",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              {
                class: [
                  "grid",
                  _vm.expanded ? "expanded-box-player" : "collapsed-box-player",
                  _vm.expandedCamPlayer ? "grid-cols-1" : "grid-cols-1", // grid-cols-2
                  _vm.camConnectError ? "player-border-error" : "player-border",
                  _vm.showUserMediaVideoPlayer ? "player-border-success" : "",
                ],
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.expandedCamPlayer,
                        expression: "!expandedCamPlayer",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.showUserMediaVideoPlayer ||
                              _vm.connectionLoading,
                            expression:
                              "!showUserMediaVideoPlayer || connectionLoading",
                          },
                        ],
                        class: [
                          !_vm.showUserMediaVideoPlayer || _vm.connectionLoading
                            ? "grid"
                            : "",
                          "justify-items-center items-center vs-loading vs-con-loading__container",
                          _vm.expanded
                            ? "player-placeholder-class-expanded"
                            : "player-placeholder-class",
                        ],
                        attrs: { id: "cam-player-placeholder-" + _vm.user.id },
                      },
                      [
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.right",
                              value: {
                                content: _vm.camConnectError
                                  ? "Erro na conexão"
                                  : "",
                              },
                              expression:
                                "{\n              content: camConnectError ? 'Erro na conexão' : ''\n            }",
                              modifiers: { right: true },
                            },
                          ],
                          class: [
                            "placeholder-icon",
                            _vm.camConnectError ? "placeholder-icon-error" : "",
                          ],
                          attrs: { icon: "tv" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.showUserMediaVideoPlayer &&
                              !_vm.connectionLoading,
                            expression:
                              "showUserMediaVideoPlayer && !connectionLoading",
                          },
                        ],
                      },
                      [
                        _c("video", {
                          ref: "userMediaVideoPlayer",
                          class: [
                            _vm.expanded ? "expanded-player" : "player",
                            "cursor-pointer",
                          ],
                          attrs: { autoplay: "" },
                          domProps: { muted: !_vm.volumeEnabled },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
      ],
      1
    ),
    _vm.expanded && _vm.showExpand
      ? _c(
          "div",
          { staticClass: "grid grid-rows-1 pt-4" },
          [
            _c("ComplexEditor", {
              staticClass: "break-words overflow-y-auto overflow-x-hidden",
              staticStyle: { "max-height": "200px", "max-width": "100%" },
              attrs: {
                editor_data: _vm.commentData,
                placeholder: _vm.$t("informe-um-comentario-aqui"),
              },
              on: {
                "update:editor_data": function ($event) {
                  _vm.commentData = $event
                },
              },
            }),
            _c(
              "div",
              { staticClass: "flex flex-row gap-2 py-2 items-end justify-end" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "float-right",
                    attrs: { label: _vm.$t("criticidade") },
                    model: {
                      value: _vm.commentSeverity,
                      callback: function ($$v) {
                        _vm.commentSeverity = $$v
                      },
                      expression: "commentSeverity",
                    },
                  },
                  [
                    _c("vs-select-item", {
                      attrs: { value: "L", text: "Baixa" },
                    }),
                    _c("vs-select-item", {
                      attrs: { value: "M", text: "Média" },
                    }),
                    _c("vs-select-item", {
                      attrs: { value: "H", text: "Alta" },
                    }),
                  ],
                  1
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "float-right mt-2",
                    on: {
                      click: function ($event) {
                        return _vm.addComment()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("salvar-comentario")))]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }